import Repository from "@/repositories/api.js"
const resource = "/admin/broken_links"

export default {
  async destroy(linkID) {
    let response = await Repository.delete(`${resource}/${linkID}`)
    return response.data
  },
  async ignore(linkID) {
    let response = await Repository.patch(`${resource}/${linkID}/ignore`)
    return response.data
  }
}
